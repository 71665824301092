import React from "react";
import "./stars.scss";

class Intro extends React.Component {
  render() {
    return (
      <div id="home" className="intro route bg-image background">
        <div id="stars" />
        <div id="stars2" />
        <div id="stars3" />

        <div className="intro-content display-table">
          <div className="table-cell">
            <div className="container">
              <h1 className="intro-title mb-4">
                Hey,
              </h1>
              <h1 className="intro-title mb-4">
                My name is Pradeep Rawat
              </h1>
              <h1 className="intro-title mb-4">
                and I am a Full-Stack Developer
              </h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
