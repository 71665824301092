import React from "react";
import { ReactComponent as Aws } from "../img/icons/aws.svg";
import { ReactComponent as Docker } from "../img/icons/docker.svg";
import { ReactComponent as Git } from "../img/icons/git.svg";
import { ReactComponent as Go } from "../img/icons/go.svg";
import { ReactComponent as Heroku } from "../img/icons/heroku.svg";
import { ReactComponent as Mongo } from "../img/icons/mongo.svg";
import { ReactComponent as Node } from "../img/icons/node.svg";
import { ReactComponent as Postgres } from "../img/icons/postgres.svg";
import { ReactComponent as Rails } from "../img/icons/rails.svg";
import { ReactComponent as ReactJS } from "../img/icons/react.svg";
import { ReactComponent as Ruby } from "../img/icons/ruby.svg";

class About extends React.Component {
  render() {
    return (
      <section id="about" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                  <h2>About Me</h2>
                  <div className="more-info">
                    <p className="lead">
                      I'm a full-stack engineer with more than 10+ years of experience. I've worked on numbers of medium to large projects of different stacks. Here are the programming languages, tools and frameworks I mostly worked on.
                    </p>
                    <p className="lang-icons">
                      <Ruby height={80} title="Ruby" />
                      <Rails height={80} title="Rails" />
                      <ReactJS height={80} title="ReactJS" />
                      <Go height={80} title="Golang" />
                      <Node height={80} title="NodeJS" />
                      <Git height={80} title="Git" />
                      <Aws height={80} title="AWS" />
                      <Docker height={80} title="Docker" />
                      <Heroku height={80} title="Heroku" />
                      <Mongo height={80} title="Mongo" />
                      <Postgres height={80} title="PostgreSQL" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
